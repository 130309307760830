<template>
  <div class="redmou" id="home">
    <div class="index_bg">
      <img src="@/assets/images/bg.png">
    </div>
    <div class="header">
      <div class="box">
        <div class="logo">
          <img src="@/assets/images/logo.png"/>
        </div>
        <div class="nav">
          <span @click="goAnchorPoint('home')" :class="{active : anchorPoint === 'home'}">Home</span>
          <span @click="goAnchorPoint('rate')" :class="{active : anchorPoint === 'rate'}">Rate</span>
          <span @click="goAnchorPoint('about')" :class="{active : anchorPoint === 'about'}">About us</span>
        </div>
      </div>
    </div>
    <div class="banner" @click="goWhatsapp">
      <img class="pc" src="@/assets/images/banner.png">
      <img class="phone" src="@/assets/images/banner_phone.png">
    </div>
    <div class="transaction" id="rate">
      <div class="box">
        <h5>
          Our transaction
          <span>New users get price reward of up to 5000 naira</span>
        </h5>
        <div class="con">
          <!-- <div class="list">
            <h5>
              Today's Highest Rate
            </h5>
            <div class="list_box">
              <div class="list_ul_box">
                <ul :class="{animate:animateRate}">
                  <li v-for="(item, index) in highestRateList" :key="index">
                    <div class="img">
                      <img :src="imgUrl+item.icon">
                    </div>
                    <div class="txt">
                      <h5>{{item.name}}</h5>
                      <span>rate up to</span>
                      <p>₦{{item.referenceAmount}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <div class="list">
            <h5>
              Gift Card Transactions
            </h5>
            <div class="list_box">
              <div class="list_ul_box">
                <ul :class="{animate:animateGift}">
                  <li 
                    v-for="(item, index) in giftCardList" 
                    :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="list">
            <h5>
              Withdrawal Transaction
            </h5>
            <div class="list_box">
              <div class="list_ul_box">
                <ul :class="{animate:animateCash}">
                  <li 
                    v-for="(item, index) in cashList" 
                    :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <span @click="goWhatsapp">Start Trade Now</span>
        </div>
      </div>
    </div>
    <div class="customers">
      <div class="box">
        <h5>
          What Our Customers Say
          <span>Watch videos to get real customer reviews</span>
        </h5>
        <div class="con">
          <div class="swiper-container swiperCustomers">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="list">
                  <video 
                    ref="customersVideo_1" 
                    src="@/assets/images/video_1.mp4" 
                    poster="@/assets/images/video_bg1.jpg"
                    loop
                    playsinline
                    webkit-playsinline
                    x-webkit-airplay
                    x5-playsinline>
                  </video>
                  <div class="bg"></div>
                  <div class="play" v-if="playShow1" @click="playVideo(1)">
                    <img src="@/assets/images/playIcon.png"/>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="list">
                  <video 
                    ref="customersVideo_2" 
                    src="@/assets/images/video_2.mp4" 
                    poster="@/assets/images/video_bg2.jpg"
                    loop
                    playsinline
                    webkit-playsinline
                    x-webkit-airplay
                    x5-playsinline>
                  </video>
                  <div class="bg"></div>
                  <div class="play" v-if="playShow2" @click="playVideo(2)">
                    <img src="@/assets/images/playIcon.png"/>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="list">
                  <video 
                    ref="customersVideo_3" 
                    src="@/assets/images/video_3.mp4" 
                    poster="@/assets/images/video_bg3.jpg"
                    loop
                    playsinline
                    webkit-playsinline
                    x-webkit-airplay
                    x5-playsinline>
                  </video>
                  <div class="bg"></div>
                  <div class="play" v-if="playShow3" @click="playVideo(3)">
                    <img src="@/assets/images/playIcon.png"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="btn">
            <span @click="goWhatsapp">Start Trade Now</span>
          </div>
        </div>
      </div>
    </div>

    <div class="payments">
      <div class="box">
        <h5>
          See Our Proof of Payments
          <span>Screenshots of offline transactions，Real, reliable trading platform</span>
        </h5>
        <div class="con">
          <div class="swiper-container swiperPayments">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="list">
                  <img src="@/assets/images/order_img1.png"/>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="list">
                  <img src="@/assets/images/order_img2.png"/>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="list">
                  <img src="@/assets/images/order_img3.png"/>
                </div>
              </div>
            </div>  
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>  
          </div>
          <div class="payments_phone">
            <img @click="changeSwiperPayments(0)" src="@/assets/images/order_img1.png"/>
            <img @click="changeSwiperPayments(1)" src="@/assets/images/order_img2.png"/>
            <img @click="changeSwiperPayments(2)" src="@/assets/images/order_img3.png"/>
          </div>
        </div>
      </div>
    </div>

    <div class="choose">
      <div class="box">
        <h5>
          Why Choose US
        </h5>
        <div class="con">
          <div class="list">
            <img src="@/assets/images/choose_1.png"/>
            <h5>Best Rate</h5>
            <p>
              We have the best gift card transaction prices in <br/> the industry!
            </p>
          </div>
          <div class="list">
            <img src="@/assets/images/choose_2.png"/>
            <h5>Fast Payment</h5>
            <p>
              In most cases, you can receive payment within <br/> 5 minutes 
            </p>
          </div>
          <div class="list">
            <img src="@/assets/images/choose_3.png"/>
            <h5>Secure</h5>
            <p>
              It is safer to deal directly with the vendor <br/> through whatsapp.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="team" id="about">
      <div class="box">
        <h5>Our Team & Environment</h5>
        <div class="con">
          <div class="conBox">
            <div class="swiper-container swiperTeam">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="list">
                    <img src="@/assets/images/team_4.png"/>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="list">
                    <img src="@/assets/images/team_2.png"/>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="list">
                    <img src="@/assets/images/team_3.png"/>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="list">
                    <img src="@/assets/images/team_1.png"/>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="list">
                    <img src="@/assets/images/team_5.png"/>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>  
            </div>
          </div>
          <div class="samllImg">
            <ul>
              <li @click="changeSwiperTeam(0)">
                <img src="@/assets/images/team_4.png"/>
              </li>
              <li @click="changeSwiperTeam(1)">
                <img src="@/assets/images/team_2.png"/>
              </li>
              <li @click="changeSwiperTeam(2)">
                <img src="@/assets/images/team_3.png"/>
              </li>
              <li @click="changeSwiperTeam(3)">
                <img src="@/assets/images/team_1.png"/>
              </li>
              <li @click="changeSwiperTeam(4)">
                <img src="@/assets/images/team_5.png"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="tips">
      <div class="bg">
        <img src="@/assets/images/contact_bg.png"/>
      </div>
      <div class="box">
        <div class="con">
          <div class="txt">
            <h5>sell giftcard and getpayment instantly</h5>
            <p>Our Professional Service Beyond your imagination.</p>
          </div>
          <div class="btn" @click="goWhatsapp">
            Start Trade Now
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="con">
        <div class="logo">
          <img src="@/assets/images/foot_logo.png"/>
        </div>
        <!-- <h5>
          Telegram : +44 7518 463 777
        </h5> -->
        <p>
          <span>Site links</span>
          |
          <span @click="goPage('privacy.html')">Privacy police</span>
          ｜
          <span @click="goPage('terms.html')">Terms & Conditions</span>
        </p>
      </div>
      <div class="bottom">
        <div class="box">
          <p>
            © 2021 Redmou.vip All Rights Reserved.
          </p>
          <h5>
            <span @click="goAnchorPoint('home')">Home</span>
            <span @click="goAnchorPoint('rate')">Rate</span>
            <span @click="goAnchorPoint('about')">About Us</span>
          </h5>
        </div>
      </div>
    </div>

    <div class="footer_phone">
      <div class="bg">
        <img src="@/assets/images/team_2.png"/>
      </div>
      <div class="contact">
        <img src="@/assets/images/contact_bg.png"/>
        <!-- <h5>Telegram : +44 7518 463 777</h5> -->
        <p @click="goPage('terms.html')">
          Privacy
          <img src="@/assets/images/contact_right.png"/>
        </p>
        <p>
          Site links
          <img src="@/assets/images/contact_right.png"/>
        </p>
        <p @click="goPage('privacy.html')">
          Privacy police
          <img src="@/assets/images/contact_right.png"/>
        </p>
      </div>
      <div class="bottom">
        <p>© 2021 Redmou.vip All Rights Reserved.</p>
        <div class="btn" @click="goWhatsapp">
          Start Trade Now
        </div>
      </div>
    </div>

    <div class="start" @click="goWhatsapp">
      Start
    </div>

    <div class="contact_box" v-loading="loading" v-if="isContact">
      <div class="bg"></div>
      <div class="con">
        <h5>Contact us</h5>
        <span @click="closeContact">
          <img src="../assets/images/close1.png">
        </span>
        <ul>
          <li @click="toWhatsapp">
            <img src="../assets/images/contact_1.png">
            WhatsApp
            <img src="../assets/images/go_icon.png">
          </li>
          <li @click="toWeChat">
            <img src="../assets/images/contact_2.png">
            WeChat
            <img src="../assets/images/go_icon.png">
          </li>
          <li @click="toTelegram">
            <img src="../assets/images/contact_3.png">
            Telegram
            <img src="../assets/images/go_icon.png">
          </li>
        </ul>
      </div>
    </div>

    <el-dialog
      title="WeChat"
      :visible="weChatShow"
      :show-close="false"
      width="300px">
      <span class="weChatImg"><img src="../assets/images/wechat.jpeg"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="weChatShow = false">Cancel</el-button>
        <el-button type="primary" @click="weChatShow = false">OK</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Loading, Button } from 'element-ui' 
import { formatTime } from '../utils/utils'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/swiper.min.css"
import 'swiper/modules/navigation.css';
import 'swiper/modules/pagination.css';

Vue.use(Dialog)
Vue.use(Button)
Vue.use(Loading.directive) 
export default {
  name: '',
  components:{

  },
  data () {
    return {
      imgUrl: '//img.tbay.store/img/',
      whatsapp: [447541932528, 447936479723, 447936489985, 447761788217, 447936779497, 447756164545, 447761787470],
      loading: false,
      isContact: false,
      allGiftCard: ['Razer', 'AMEX', 'Steam', 'iTunes', 'Google', 'Vanilla', 'Sephora', 'Mastercard', 'Nordstorm', 'Visa', 'Amazon', 'Ebay', 'Footlocker', 'Macy', 'Nike', 'Xbox', 'Roblox', 'Finl', 'Cvs', 'Best Buy', 'Lululemon', 'Walmart', 'Greendot', 'Cash App', 'GoBank', 'Zelle Pay', 'Walmart', 'Chime', 'Paysafecard', 'GameStop', 'Netspend', 'PlayStation'],
      giftCardList: [],
      animateGift: false,
      intervalGift: undefined,
      cashList: [],
      cashTime: ['30s', '1min', '2min', '3min'],
      animateCash: false,
      intervalCash: undefined,
      highestRateList: [],
      animateRate: false,
      intervalRate: undefined,
      weChatShow: false,
      //swiper切换
      swiperCustomers: null,
      swiperPayments: null,
      swiperTeam: null,
      playShow1: false,
      playShow2: false,
      playShow3: false,
      anchorPoint: 'home',
    }
  },
  computed:{
    bannerHeight(){
      if(document.body.clientWidth>768){
        return '420px'
      }else{
        return (document.body.clientWidth*0.9 - 40)*390/480+'px'
      }
    }
  },
  methods:{
    //前往whatsapp
    goWhatsapp(){
      //this.isContact = true
      gtag('event', 'contact_us')
      this.toWhatsapp()
    },
    closeContact(){
      this.isContact = false
      //gtag('event', 'go_to_chat')
    },
    //前往Whatsapp
    toWhatsapp(){
      const whatsappUrl = `https://api.whatsapp.com/send/?phone=${this.whatsapp[Math.floor(Math.random()*this.whatsapp.length)]}`
      window.open(whatsappUrl)
      gtag('event', 'go_to_Whatsapp')
    },
    //前往WeChat
    toWeChat(){
      this.weChatShow = true
      gtag('event', 'go_to_WeChat')
    },
    //前往Telegram
    toTelegram(){
      window.open('https://t.me/+447518463777')
      gtag('event', 'go_to_Telegram')
    },
    createdGiftCardList(){
      const Arr = []
      for(let i = 0; i < 20; i++){
        Arr.push(`${formatTime(new Date())} ${String.fromCharCode(Math.floor( Math.random() * 26) + "A".charCodeAt(0))}***${String.fromCharCode(Math.floor( Math.random() * 26) + "a".charCodeAt(0))} ${this.allGiftCard[Math.floor(Math.random()*this.allGiftCard.length)]} ${(Math.floor(Math.random()*10)+1)*50} Successful transation`)
      }
      return Arr
    },
    getRandomGiftCard(){
      return `${formatTime(new Date())} ${String.fromCharCode(Math.floor( Math.random() * 26) + "A".charCodeAt(0))}***${String.fromCharCode(Math.floor( Math.random() * 26) + "a".charCodeAt(0))} ${this.allGiftCard[Math.floor(Math.random()*this.allGiftCard.length)]} ${(Math.floor(Math.random()*10)+1)*50} Successful transation`
    },
    createdCashList(){
      const Arr = []
      for(let i = 0; i < 20; i++){
        Arr.push(`${formatTime(new Date())} ${String.fromCharCode(Math.floor( Math.random() * 26) + "A".charCodeAt(0))}***${String.fromCharCode(Math.floor( Math.random() * 26) + "a".charCodeAt(0))} Withdrawal Successful in ${this.cashTime[Math.floor(Math.random()*this.cashTime.length)]}`)
      }
      return Arr
    },
    getRandomCash(){
      return `${formatTime(new Date())} ${String.fromCharCode(Math.floor( Math.random() * 26) + "A".charCodeAt(0))}***${String.fromCharCode(Math.floor( Math.random() * 26) + "a".charCodeAt(0))} Withdrawal Successful in ${this.cashTime[Math.floor(Math.random()*this.cashTime.length)]}`
    },
    giftCardScrollUp(){
      this.intervalGift = setInterval(() =>{
        this.giftCardScrollUpFunction()
      }, 2030)
    },
    giftCardScrollUpFunction(){
      this.animateGift = true
      setTimeout(()=>{
        this.giftCardList.push(this.getRandomGiftCard())
        this.giftCardList.shift()
        this.animateGift = false
      },2000)
    },
    cashScrollUp(){
      this.intervalCash = setInterval(() =>{
        this.cashScrollUpFunction()
      },2030)
    },
    cashScrollUpFunction(){
      this.animateCash = true
      setTimeout(() =>{
        this.cashList.push(this.getRandomCash())
        this.cashList.shift()
        this.animateCash = false
      },2000)
    },
    //获取每日最高汇率
    async getHighestRate(){
      const res = await this.$api.home.highestRate({
        params:{}
      })
      this.highestRateList = res
    },
    rateScrollUp(){
      this.intervalRate = setInterval(() =>{
        this.rateScrollUpFunction()
      }, 4030)
    },
    rateScrollUpFunction(){
      this.animateRate = true
      setTimeout(() =>{
        this.highestRateList.push(this.highestRateList[0])
        this.highestRateList.shift()
        this.animateRate = false
      }, 4000)
    },
    //跳转页面
    goPage(page){
      location.href = `./${page}`
    },
    //图片切换
    changeSwiperTeam(index){
      this.swiperTeam.slideToLoop(index)
    },
    changeSwiperPayments(index){
      this.swiperPayments.slideToLoop(index)
    },
    //视频切换
    chanheSwiperCustomers(index){
      this.$refs.customersVideo_1.pause()
      this.$refs.customersVideo_2.pause()
      this.$refs.customersVideo_3.pause()
      if(index === 0){
        this.playShow1 = true
        this.playShow2 = false
        this.playShow3 = false
      }
      if(index === 1){
        this.playShow1 = false
        this.playShow2 = true
        this.playShow3 = false
      }
      if(index === 2){
        this.playShow1 = false
        this.playShow2 = false
        this.playShow3 = true
      }
    },
    playVideo(index){
      if(index === 1){
        this.$refs.customersVideo_1.play()
        this.playShow1 = false
      }
      if(index === 2){
        this.$refs.customersVideo_2.play()
        this.playShow2 = false
      }
      if(index === 3){
        this.$refs.customersVideo_3.play()
        this.playShow3 = false
      }
    },
    //锚点跳转
    goAnchorPoint(params){
      this.anchorPoint = params
      var ID = `#${params}`
      document.querySelector(ID).scrollIntoView({
        behavior: "smooth",
        block: 'start',
        inline: 'start'
      })
    }
  },
  async created(){
    this.giftCardList = this.createdGiftCardList()
    this.giftCardScrollUpFunction()
    this.giftCardScrollUp()
    this.cashList = this.createdCashList()
    this.cashScrollUpFunction()
    this.cashScrollUp()
    await this.getHighestRate()
    this.rateScrollUpFunction()
    this.rateScrollUp()
  },
  mounted(){
    this.swiperCustomers = new Swiper('.swiperCustomers', {
      //loop: true,
      initialSlide: 1,
      centeredSlides: true,
      slidesPerView: document.body.clientWidth > 768 ? 2.4 : 2,
      spaceBetween: document.body.clientWidth > 768 ? 50 : 20,
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable :true,
      },
      on:{
        transitionEnd: (swiper) =>{
          this.chanheSwiperCustomers(swiper.realIndex)
        },
      }
    })
    this.swiperPayments = new Swiper('.swiperPayments', {
      slidesPerView: document.body.clientWidth > 768 ? 3 : 1,
      spaceBetween: document.body.clientWidth > 768 ? 0 : 16,
      loop: true,
      modules: [Navigation],
      navigation: {
        nextEl: '.swiperPayments .swiper-button-next',
        prevEl: '.swiperPayments .swiper-button-prev',
      },
    })
    this.swiperTeam = new Swiper('.swiperTeam', {
      loop: true,
      modules: [Navigation],
      navigation: {
        nextEl: '.swiperTeam .swiper-button-next',
        prevEl: '.swiperTeam .swiper-button-prev',
      },
    })
  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.redmou{
  background: #F6F9FD;
  overflow-x: hidden;
  position: relative;
  z-index: 2;
  .index_bg{
    width: 100%;
    position: absolute;
    top: 25.2%;
    left: 0%;
    z-index: -1;
    img{
      width: 100%;
    }
  }
  .header{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 0;
    background: rgba(255,255,255,0);
    box-shadow: 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 2px 54px 0px rgba(14,50,192,0.05);
    backdrop-filter: blur(5px);
    .box{
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo{
        img{
          display: block;
          height: 70px;
        }
      }
      .nav{
        display: flex;
        align-items: center;
        span{
          font-size: 16px;
          font-weight: 400;
          color: #677897;
          line-height: 22px;
          padding: 7px 20px;
          background: transparent;
          border-radius: 18px;
          cursor: pointer;
          -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
          transition: all cubic-bezier(.18,1,.21,1) .9s;
        }
        span.active{
          color: #FFFFFF;
          background: #0E32C0;
        }
      }
    }
  }
  .banner{
    cursor: pointer;
    background-color: #f6fffa;
    img{
      width: 100%;
    }
  }
  img.pc{
    display: block !important;
  }
  img.phone{
    display: none !important;
  }
  .transaction{
    padding: 40px 0;
    .box{
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
      >h5{
        font-size: 36px;
        font-weight: 400;
        color: #222222;
        line-height: 42px;
        margin-bottom: 60px;
        text-align: center;
        span{
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: #0E32C0;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
    .con{
      display: flex;
      width: 100%;
      .list{
        flex: 1;
        .list_box{
          background: #FFFFFF;
          box-shadow: 0px 10px 30px 0px rgba(14,50,192,0.2);
          border-radius: 24px;
          padding: 20px 16px;
        }
        >h5{
          font-size: 16px;
          font-weight: 500;
          color: #677897;
          line-height: 30px;
          margin-bottom: 12px;
        }
      }
      .list:nth-child(2), .list:last-child{
         margin: 0 2%;
        .list_ul_box{
          height: 240px;
          overflow: hidden;
        }
        ul{
          line-height: 40px;
          transition: all 0s linear;
          list-style: none;
          padding: 0;
          margin: 0;
          li{
            font-size: 13px;
            font-weight: 500;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
        ul.animate{
          transition: all 2s linear;
          margin-top: -40px;
        }
      }
      .list:first-child{
        .list_ul_box{
          height: 240px;
          overflow: hidden;
        }
        ul{
          transition: all 0s linear;
          list-style: none;
          padding: 0;
          margin: 0;
          li{
            display: flex;
            align-items: center;
            padding: 12px 0;
            border-bottom: 2px dotted rgba(84, 124, 168, .1);
            .img{
              img{
                width: 88px;
                height: 56px;
                display: block;
                border-radius: 10px;
              }
            }
            .txt{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              margin-left: 16px;
              h5{
                font-size: 15px;
                font-weight: 600;
                color: #333333;
                margin: 0;
                flex: 1;
                text-align: left;
              }
              span{
                font-size: 12px;
                font-weight: 400;
                color: #959EB0;
                flex: 1;
                text-align: left;
              }
              p{
                font-size: 16px;
                font-weight: 600;
                color: #0E32C0;
                flex: 1;
                text-align: left;
              }
            }
          }
        }
        ul.animate{
          transition: all 4s linear;
          margin-top: -82px;
        }
      }
    }
    .btn{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      span{
        padding: 15px 40px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 21px;
        background: linear-gradient(135deg, #0071E6 0%, #0E32C0 100%);
        border-radius: 100px;
      }
    }
  }
  .customers{
    padding: 40px 0 90px 0;
    .box{
      position: relative;
      z-index: 2;
      >h5{
        font-size: 36px;
        font-weight: 400;
        color: #222222;
        line-height: 42px;
        margin-bottom: 50px;
        text-align: center;
        span{
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: #677897;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
    .con{
      .swiperCustomers{
        padding: 3% 0;
        .swiper-slide{
          -webkit-transform:scale(.8);
          transform:scale(.8);
          -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;
          transition: all cubic-bezier(.18,1,.21,1) .9s;
        }
        .swiper-slide.swiper-slide-active{
          -webkit-transform:scale(1.2);
          transform:scale(1.2);
        }
      }
      .list{
        box-shadow: 0px 10px 30px 0px rgba(14,50,192,0.2);
        border-radius: 24px;
        overflow: hidden;
        video{
          width: 100%;
        }
        .bg{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 24px;
          background: linear-gradient(89deg, #0843FA 0%, #1086FF 55%, #002FFF 100%);
          opacity:0.2;
          -moz-opacity:0.2; 
          -khtml-opacity:0.2;
          -webkit-opacity:0.2;
          filter:alpha(opacity=20);
        }
        .play{
          width: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          cursor: pointer;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .btn{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        span{
          padding: 15px 40px;
          font-size: 18px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 21px;
          background: linear-gradient(135deg, #0071E6 0%, #0E32C0 100%);
          border-radius: 100px;
        }
      }
    }
  }

  .payments{
    padding: 40px 0; 
    .box{
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
      overflow: hidden;
      >h5{
        font-size: 36px;
        font-weight: 400;
        color: #222222;
        line-height: 42px;
        margin-bottom: 50px;
        text-align: center;
        span{
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: #677897;
          line-height: 30px;
          margin-top: 10px;
        }
      }
      .con{
        padding: 0 60px;
        position: relative;
        .swiperPayments{
          overflow: hidden;
        }
        .swiper-button-prev, .swiper-button-next{
          width: 36px;
          height: 36px;
          color: rgba(142, 148, 157, 1);
          --swiper-navigation-size: 20px;
          background: #E6E6E6;
          border-radius: 4px;
          margin-top: -18px;
        }
        .list{
          img{
            display: block;
            width: 100%;
          }
        }
        .payments_phone{
          display: none;
        }
      }
    }
  }

  .choose{
    padding: 40px 0;
    .box{
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
      >h5{
        font-size: 36px;
        font-weight: 400;
        color: #222222;
        line-height: 42px;
        margin-bottom: 50px;
        text-align: center;
      }
      .con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list{
          flex: 1;
          text-align: center;
          img{
            display: block;
            width: 80px;
            margin: 0 auto;
          }
          h5{
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            line-height: 32px;
            margin: 14px 0 18px 0;
          }
          p{
            font-size: 16px;
            font-weight: 400;
            color: #677897;
            line-height: 26px;
          }
        }
      }
    }
  }
  .team{
    padding: 80px 0 120px 0;
    .box{
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
      >h5{
        font-size: 36px;
        font-weight: 400;
        color: #222222;
        line-height: 42px;
        margin-bottom: 50px;
        text-align: center;
      }
      .con{
        padding-right: 16%;
        position: relative;
        .conBox{
          width: 100%;
          position: relative;
          border-radius: 24px;
          overflow: hidden;
          .swiperTeam{
            overflow: hidden;
          }
          .swiper-button-prev, .swiper-button-next{
            width: 36px;
            height: 36px;
            color: rgba(142, 148, 157, 1);
            --swiper-navigation-size: 20px;
            background: #E6E6E6;
            border-radius: 4px;
            margin-top: -18px;
          }
          .swiper-button-prev{
            left: 18px;
          }
          .swiper-button-next{
            right: 18px;
          }
          .list{
            img{
              display: block;
              width: 100%;
            }
            p{
              font-size: 20px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 56px;
              padding: 0 20px;
              background-color: #2c2a2f;
            }
          }
        }
        .samllImg{
          width: 14.3%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          ul{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            display: block;
            li{
               width: 100%;
               height: 17.75%;
               display: block;
              list-style: none;
              margin-bottom: 10%;
              cursor: pointer;
              img{
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
                transition: all cubic-bezier(.18,1,.21,1) .9s;
              }
              img:hover{
                width: 110%;
              }
            }
            li:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .tips{
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 60px 0;
    .bg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .box{
      position: relative;
      z-index: 2;
      max-width: 1280px;
      margin: 0 auto;
      .con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .txt{
          h5{
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            margin-bottom: 12px;
            text-transform: uppercase;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: #A9B7E9;
            line-height: 16px;
          }
        }
        .btn{
          font-size: 18px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
          padding: 15px 40px;
          border-radius: 100px;
          border: 1px solid #FFFFFF;
          cursor: pointer;
        }
      }
    }  
  }

  .start{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    cursor: pointer;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 21px;
    background: linear-gradient(135deg, #0071E6 0%, #0E32C0 100%);
    border-radius: 100px;
  }

  .footer{
    width: 100%;
    .con{
      width: 100%;
      padding: 40px 0;
      background-color: #fff;
      .logo{
        width: 100px;
        margin: 0 auto;
        margin-bottom: 30px;
        img{
          display: block;
          width: 100%;
        }
      }
      h5{
        font-size: 24px;
        font-weight: 500;
        color: #677897;
        line-height: 30px;
        letter-spacing: 1px;
        margin-bottom: 30px;
        text-align: center;
      }
      p{
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #B0B9CA;
        line-height: 30px;
        span{
          font-size: 16px;
          font-weight: 400;
          color: #B0B9CA;
          line-height: 30px;
          margin: 0 30px;
          cursor: pointer;
        }
      }
    } 
    .bottom{
      background: #677897;
      padding: 28px 0;
      .box{
        position: relative;
        z-index: 2;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          font-size: 14px;
          font-weight: 400;
          color: #A9B7E9;
          line-height: 22px;
        }
        h5{
          display: flex;
          align-items: center;
          span{
            font-size: 16px;
            font-weight: 400;
            color: #A9B7E9;
            line-height: 26px;
            letter-spacing: 1px;
            margin-left: 40px;
            -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
            transition: all cubic-bezier(.18,1,.21,1) .9s;
          }
          span:hover{
            color: #FDFDFF;
            cursor: pointer;
          }
        }
      }
    }
  }

  .footer_phone{
    display: none;
  }

  .contact_box{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .bg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.3);
      z-index: -1;
    }
    .con{
      width: 80%;
      max-width: 500px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: #fff;
      border-radius: 10px;
      padding-top: 0;
      >h5{
        font-size: 24px;
        color: #212121;
        line-height: 3;
        text-align: center;
        border-bottom: 1px solid #e8e8e8;
      }
      >span{
        position: absolute;
        top: 0;
        right: -50px;
        cursor: pointer;
        img{
          width: 30px;
        }
      }
      ul{
        margin: 0;
        padding: 30px 50px;
        li{
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          font-size: 20px;
          font-weight: 500;
          color: #353535;
          padding: 6px 12px;
          background: #f2f2f2;
          border-radius: 60px;
          margin-bottom: 30px;
          img:first-child{
            width: 42px;
            display: block;
            margin-right: 24px;
          }
          img:last-child{
            width: 24px;
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -8px;
          }
        }
      }
    }
  }

}
@media (max-width: 768px){
  .redmou{
    .index_bg{
      display: none;
    }
    .box{
      width: 90%;
    }
    img.pc{
      display: none !important;
    }
    img.phone{
      display: block !important;
    }
    .header{
      padding: 5px 0;
      .box{
        .logo{
          margin: 0 auto;
          img{
            height: 40px;
          }
        }
        .nav{
          display: none;
        }
      }
    }
    .banner{
      margin-top: 60px;
    }
    .transaction{
      padding: 20px 0;
      .box{
        >h5{
          display: none;
        }
        .con{
          flex-wrap: wrap;
          .list{
            width: 100%;
            flex: none;
            margin-bottom: 20px;
            h5{
              font-size: 18px;
            }
            .list_box{
              border-radius: 16px;
            }
          }
          .list:last-child{
            margin-bottom: 0;
          }
        }
        .btn{
          display: none;
        }
      }
    }
    .customers{
      padding: 20px 0;
      .box{
        width: 100%;
        >h5{
          padding: 0 20px;
          font-size: 18px;
          color: #677897;
          line-height: 32px;
          text-align: left;
          margin-bottom: 20px;
          span{
            display: none;
          }
        }
      }
      .con{
        .swiperCustomers{
          padding: 5% 0;
          .swiper-slide.swiper-slide-active{
            -webkit-transform:scale(1.1);
            transform:scale(1.1);
          }
        }
        .list{
          height: 360px;
          position: relative;
          video{
            width: auto;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
        .btn{
          display: none;
        }
        .swiper-pagination{
          display: none;
        }
      }
    }
    .payments{
      padding: 20px 0;
      .box{
        >h5{
          font-size: 18px;
          color: #677897;
          line-height: 32px;
          text-align: left;
          margin-bottom: 20px;
          span{
            display: none;
          }
        }
        .con{
          padding: 0;
          .swiper-button-prev{
            display: none;
          }
          .swiper-button-next{
            display: none;
          }
          .payments_phone{
            display: block;
            margin-top: 12px;
            display: flex;
            align-items: flex-start;
            height: 46px;
            img{
              width: 58px;
              margin-right: 10px;
              -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
              transition: all cubic-bezier(.18,1,.21,1) .9s;
            }
            img:hover{
              width: 66px;
            }
          }
        }
      }
    }
    .choose{
      padding: 20px 0;
      .box{
        >h5{
          font-size: 18px;
          color: #677897;
          line-height: 32px;
          text-align: left;
          margin-bottom: 20px;
        }
        .con{
          flex-wrap: wrap;
          .list{
            width: 100%;
            flex: none;
            background: #FFFFFF;
            box-shadow: 0px 3px 20px 0px rgba(14,50,192,0.1);
            border-radius: 16px;
            padding: 20px 10px;
            padding-left: 100px;
            margin-bottom: 20px;
            position: relative;
            text-align: left;
            img{
              position: absolute;
              top: 20px;
              left: 10px;
            }
            h5{
              font-size: 20px;
              font-weight: 500;
              color: #222222;
              line-height: 32px;
              margin: 0;
              margin-bottom: 4px;
            }
            p{
              font-size: 13px;
              font-weight: 400;
              color: #677897;
              line-height: 15px;
              br{
                display: none;
              }
            }
          }
          .list:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .team{
      padding: 20px 0;
      .box{
        >h5{
          font-size: 18px;
          color: #677897;
          line-height: 32px;
          text-align: left;
          margin-bottom: 20px;
        }
        .con{
          padding-right: 0;
          .conBox{
            border-radius: 16px;
            .swiper-button-prev{
              left: 10px;
            }
            .swiper-button-next{
              right: 10px;
            }
            .list{
              p{
                font-size: 12px;
                line-height: 30px;
              }
            }
          }
          .samllImg{
            position: relative;
            width: 100%;
            margin-top: 16px;
            height: 42px;
            ul{
              position: relative;
              display: flex;
              height: auto;
              li{
                margin-left: 12px;
                height: auto;
                img{
                  height: auto;
                }
              }
              li:first-child{
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .tips{
      display: none;
    }
    .footer{
      display: none;
    }
    .footer_phone{
      display: block;
      padding-top: 55%;
      position: relative;
      z-index: 2;
      .bg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        img{
          width: 100%;
        }
      }
      .contact{
        position: relative;
        z-index: 2;
        overflow: hidden;
        padding: 20px;
        >img{
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
        h5{
          font-size: 20px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 30px;
          margin-bottom: 30px;
          opacity:0.8;
          -moz-opacity:0.8; 
          -khtml-opacity:0.8;
          -webkit-opacity:0.8;
          filter:alpha(opacity=80);
        }
        p{
          font-size: 16px;
          font-weight: 400;
          color: #F1F4Fe;
          line-height: 30px;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          opacity:0.8;
          -moz-opacity:0.8; 
          -khtml-opacity:0.8;
          -webkit-opacity:0.8;
          filter:alpha(opacity=80);
          img{
            width: 16px;
          }
        }
        p:last-child{
          margin-bottom: 0;
        }
      }
      .bottom{
        padding: 20px;
        background-color: #d5def0;
        p{
          font-size: 12px;
          font-weight: 400;
          color: #677897;
          line-height: 22px;
          margin-bottom: 20px;
        }
        .btn{
          width: 210px;
          background: linear-gradient(135deg, #0071E6 0%, #0E32C0 100%);
          border-radius: 100px;
          font-size: 18px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
          padding: 15px 0;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    .contact_box{
      .con{
        >span{
          top: -50px;
          right: 0;
        }
      }
    }
    
  }
}
.weChatImg{
  img{
    display: block;
    width: 100%;
  }
}
</style>

<style lang="less">
.redmou{
  .el-dialog__body{
    padding: 20px;
  }
  .customers{
    .swiper-pagination{
      bottom: -50px;
      .swiper-pagination-bullet{
        padding: 4px;
        width: 30px;
        height: 2px;
        background: none;
        border-radius: 0;
        opacity:1;
        -moz-opacity:1; 
        -khtml-opacity:1;
        -webkit-opacity:1;
        filter:alpha(opacity=100);
        position: relative;
      }
      .swiper-pagination-bullet::before{
        content: '';
        width: 30px;
        height: 2px;
        position: absolute;
        top: 4px;
        left: 4px;
        background: #A9B7E9;
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active::before{
        background: #0E32C0;
      }
    }
  }
}
</style>
